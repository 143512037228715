<template>
	<div class="main-content">
		<ktv-dialog v-model="is.showing.deleteDialog" title="Hapus Data" text="Apakah Anda ingin menghapus data ini?">
			<template #footer>
				<ktv-button text="Hapus" :loading="is.loading" @click="deleteData()" />
				<ktv-button text="Batal" color="light" :loading="is.loading" class="ml-2" @click="closeDeleteDialog()" />
			</template>
		</ktv-dialog>
		<ktv-breadcrumb title="Loan Package List" page="Package" folder="Loan" />
		<ktv-table
			:columns="columns"
			:filter="false"
			:is-loading="is.loading"
			:line-numbers="true"
			:rows="packageList"
			:search-enabled="false"
			:total-rows="totalRows"
			mode="remote"
			@on-page-change="onPageChange"
			@on-per-page-change="onPerPageChange"
			@on-sort-change="onSortChange"
		>
			<template #rows="{ props }">
				<span v-if="props.column.field === 'button'">
					<b-dropdown size="xs" variant="link" toggle-class="text-decoration-none" no-caret>
						<template #button-content>
							<span>
								<i class="nav-icon text-14 i-Arrow-Down-in-Circle" />
							</span>
						</template>
						<b-dropdown-item>
							<router-link class="d-flex flex-grow" :to="{ name: 'Loan Package Data', params: { id: props.row.loanPackageId } }">
								<i class="i-Eye text-14 text-success mr-2" />
								View
							</router-link>
						</b-dropdown-item>
						<b-dropdown-item>
							<router-link class="d-flex flex-grow" :to="{ name: 'Loan Package Update', params: { id: props.row.loanPackageId } }">
								<i class="i-Pen-3 text-14 text-success mr-2" />
								Update
							</router-link>
						</b-dropdown-item>
						<b-dropdown-item @click="showDeleteDialog(props.row)">
							<a href="#">
								<i class="i-Close-Window text-14 text-danger mr-2" />
								Delete
							</a>
						</b-dropdown-item>
					</b-dropdown>
				</span>
				<span v-else-if="props.column.field === 'totalCashAmount'">{{ props.row.totalCashAmount | Rupiah }}</span>
				<span v-else-if="props.column.field === 'totalProductAmount'">{{ props.row.totalProductAmount | Rupiah }}</span>
			</template>
		</ktv-table>
	</div>
</template>
<script>
	import { each } from "underscore"
	import { KtvButton, KtvDialog, KtvTable } from "@/components"
	import { mapActions, mapState } from "vuex"

	export default {
		name: "LoanPackageList",
		metaInfo: {
			title: "Loan Package List",
		},
		components: { KtvButton, KtvDialog, KtvTable },
		data() {
			return {
				columns: [
					{
						field: "button",
						html: true,
						sortable: false,
						tdClass: "text-center",
						thClass: "text-center",
					},
					{
						field: "loanPackageId",
						label: "ID",
						thClass: "text-left",
					},
					{
						field: "packageName",
						label: "Package Name",
						thClass: "text-left",
					},
					{
						field: "totalCashAmount",
						label: "Cash Amount",
						thClass: "text-left",
					},
					{
						field: "totalProductAmount",
						label: "Product Amount",
						thClass: "text-left",
					},
					{
						field: "product",
						label: "Product",
						thClass: "text-left",
					},
					{
						field: "kioskName",
						label: "Kiosk",
						thClass: "text-left",
					},
				],
				deletePackageId: null,
				is: {
					loading: false,
					showing: {
						deleteDialog: false,
					},
				},
				params: {
					limit: 10,
					order: null,
					page: 1,
					search: null,
				},
				searchParams: null,
			}
		},
		computed: {
			...mapState("PACKAGE", ["packageList", "totalRows"]),
		},
		created() {
			this.getData()
		},
		methods: {
			...mapActions({
				deletePackage: "PACKAGE/deletePackage",
				getPackageList: "PACKAGE/getPackageList",
			}),
			closeDeleteDialog() {
				this.deletePackageId = null

				this.is.showing = {
					...this.is.showing,
					deleteDialog: false,
				}

				this.is = {
					...this.is,
					loading: false,
				}
			},
			deleteData() {
				this.is = {
					...this.is,
					loading: true,
				}

				this.deletePackage(this.deletePackageId).then(() => {
					this.closeDeleteDialog()
				})
			},
			getData() {
				this.is = {
					...this.is,
					loading: true,
				}

				this.getPackageList(this.params).then(() => {
					this.is = {
						...this.is,
						loading: false,
					}
				})
			},
			onFilter(by = null, queryText = null) {
				if (by) {
					this.searchParams = {
						...this.searchParams,
						[by]: queryText,
					}
					let searchParam = ""

					each(this.searchParams, (param, paramKey) => {
						if (searchParam === "") {
							searchParam += `${paramKey}=${param}`
						} else {
							searchParam += `&${paramKey}=${param}`
						}
					})

					this.updateParams({
						search: searchParam,
					})

					this.getData()
				}
			},
			onPageChange({ currentPage }) {
				this.updateParams({ page: currentPage })

				this.getData()
			},
			onPerPageChange({ currentPerPage }) {
				this.updateParams({ limit: currentPerPage, page: 1 })

				this.getData()
			},
			onSortChange($event) {
				const { field, type } = $event[0]
				let orderQuery = null

				if (type !== "none") {
					orderQuery = "namaField=" + field + "&orderBy=" + type.toUpperCase()
				} else {
					orderQuery = null
				}

				this.updateParams({ order: orderQuery })

				this.getData()
			},
			showDeleteDialog({ loanPackageId }) {
				this.deletePackageId = loanPackageId

				this.is.showing = {
					...this.is.showing,
					deleteDialog: true,
				}
			},
			updateParams(params) {
				this.params = { ...this.params, ...params }
			},
		},
	}
</script>
